import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { REACT_APP_RC_TWILIO_URL } from '../config/constants';

// types

export type PhoneNumberType = {
    name: string;
    phoneNumber: string;
    twilioId: string;
    voiceUrl: string;
    smsUrl: string;
    statusCallback: string;
    id: string;
    channel: { id: string; name: string; type: string };
    company: { id: string; name: string; logo: string };
};

type InitialStateType = {
    total: number;
    numbers: PhoneNumberType[];
    availableNumbers: PhoneNumberType[];
    activeNumbers: PhoneNumberType[];
    activeNumbersForSelectedCompany: PhoneNumberType[];
    loading: boolean;
    error: string | null;
};

type UpdateKeysType = {
    [key: string]: string;
};

// state

const name: string = 'twilio';

const initialState: InitialStateType = {
    total: 0,
    numbers: [],
    availableNumbers: [],
    activeNumbers: [],
    activeNumbersForSelectedCompany: [],
    loading: false,
    error: null,
};

// implementation

let getTotalCache = false; //see comment line 67

const createExtraActions = () => {
    const getNumbers = createAsyncThunk(
        `${name}/numbers`,
        async ({
            pageNumber,
            pageSize,
            filters,
            getTotal,
        }: {
            pageNumber: number;
            pageSize: number;
            filters?: { source: string; filter: string; value: string; type: string }[];
            getTotal: boolean;
        }) => {
            console.log('filters', filters);
            //compare getTotal and getTotalCache values to avoid sending several times the same request (especially when getTotal = true). Else if getTotal = false, request is sent.
            if ((getTotal && !getTotalCache) || !getTotal) {
                //ensure getTotal and getTotalCache are the same if several identical requests so the condition (getTotal && !getTotalCache) evaluates to false, therefore request is not sent again
                getTotalCache = getTotal;
                return axios
                    .get(`${REACT_APP_RC_TWILIO_URL}/numbers?page=${pageNumber}&limit=${pageSize}&getTotal=${getTotal}`)
                    .then((response) => {
                        return response.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    );

    const getAvailablePhoneNumbers = createAsyncThunk(`${name}/getAvailableNumbers`, async () => {
        return axios
            .get(`${REACT_APP_RC_TWILIO_URL}/numbers/available`)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const getActivePhoneNumbers = createAsyncThunk(`${name}/getActiveNumbers`, async () => {
        return axios
            .get(`${REACT_APP_RC_TWILIO_URL}/numbers/active`)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    });

    const getActiveNumbersForCompany = createAsyncThunk(
        `${name}/getActiveNumbersForCompany`,
        async (companyId: string = '') => {
            return axios
                .get(`${REACT_APP_RC_TWILIO_URL}/numbers/active/company/${companyId}`)
                .then((response) => {
                    return response.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    );

    const releaseNumber = createAsyncThunk(`${name}/clear`, async (id: string = '') => {
        return axios
            .put(`${REACT_APP_RC_TWILIO_URL}/numbers/${id}/url`, {
                voiceUrl: '',
                statusCallback: '',
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err);
            });
    });

    return {
        getNumbers: getNumbers,
        getAvailablePhoneNumbers: getAvailablePhoneNumbers,
        getActivePhoneNumbers: getActivePhoneNumbers,
        getActiveNumbersForCompany: getActiveNumbersForCompany,
        releaseNumber: releaseNumber,
    };
};

const updateKeyInState: UpdateKeysType = {
    getNumbers: 'numbers',
    getAvailablePhoneNumbers: 'availableNumbers',
    getActivePhoneNumbers: 'activeNumbers',
    getActiveNumbersForCompany: 'activeNumbersForSelectedCompany',
};

const createExtraReducers = (builder: any) => {
    const actions = Object.entries(createExtraActions());
    return actions.map((action) => {
        const functionName = action[0];
        const func = action[1];
        return builder
            .addCase(func.pending, (state: InitialStateType) => {
                state = { ...state, loading: true };
                return state;
            })
            .addCase(
                func.fulfilled,
                (state: InitialStateType, action: { payload: { numbers: PhoneNumberType[]; total: number } }) => {
                    state = action.payload
                        ? {
                              ...state,
                              loading: false,
                              total: action.payload.total === 0 ? state.total : action.payload.total,
                              [updateKeyInState[functionName]]: action.payload.numbers.map(
                                  (el: any, index: number) => ({ ...el, id: el.id || index } as PhoneNumberType),
                              ),
                          }
                        : state;
                    return state;
                },
            )
            .addCase(func.rejected, (state: InitialStateType, action: { error: string }) => {
                state = { ...state, loading: false, error: action.error };
                return state;
            });
    });
};

const extraActions = createExtraActions();
const slice = createSlice({
    name,
    initialState,
    reducers: {
        clearGetTotalCache() {
            getTotalCache = false;
        },
    },
    extraReducers: (builder) => createExtraReducers(builder),
});

// exports

export const twilioActions = { ...slice.actions, ...extraActions };
export const twilioReducer = slice.reducer;
