import React, { useEffect, useState, useMemo } from "react";
import { RcMenu, CompanyLogo } from "@reecall/components_library/build/index"
import { NavLink, Button } from "reactstrap"
import { NavLink as Link, useLocation } from "react-router-dom"
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { BookmarkHandler } from "../../app/pages/companies/List";
import { companiesActions } from "../../store/CompaniesSlice";


const NavLinkDefaultComponent = ({ children, ...props }) => <NavLink
    tag={Link}
    style={{height: "29px"}}
    {...props}
>
    {children}
</NavLink>


const MenuTest = [
    {
        type: "group",
        label: "Dashboards",
        collapse: true,
        id: "main-dashboards",
        childrens: [
            {
                type: "item",
                label: "Companies",
                icon: "buildings",
                id: "dashboards-companies",
                navLinkProps: {
                    to: "/companies"
                }
            },
            {
                type: "item",
                label: "Twilio",
                icon: "phone-incoming",
                id: "twilio",
                navLinkProps: {
                    to: "/twilio"
                }
            },
            // {
            //     type: "item",
            //     label: "Metrics",
            //     icon: "chart-simple",
            //     id: "dashboards-metrics",
            //     navLinkProps: {
            //         to: "/dashboards-companies"
            //     }
            // }
        ]
    },
    {
        type: "group",
        label: "Bookmarks",
        icon: "star",
        id: "sidebar-bookmarks",
        childrens: [

        ]
    },
    // {
    //     type: "group",
    //     label: "Ma petite Boutique",
    //     icon: "company",
    //     id: "main-company",
    //     collapse: true,
    //     sortable: true,
    //     childrens: [
    //         {
    //             type: "item",
    //             label: "Analytics",
    //             icon: "metrics",
    //             id: "company-analytics",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Conversations",
    //             icon: "conversation",
    //             id: "company-conversations",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Tickets",
    //             icon: "ballot-check",
    //             id: "company-tickets",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Contacts",
    //             icon: "users",
    //             id: "company-contacts",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Processes",
    //             icon: "wand-magic-sparkles",
    //             id: "company-processes",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Events",
    //             icon: "bolt",
    //             id: "company-events",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "References",
    //             icon: "books",
    //             id: "company-references",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Exports",
    //             icon: "exports",
    //             id: "company-exports",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Channels",
    //             icon: "channels",
    //             id: "company-channels",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Agents",
    //             icon: "agents",
    //             id: "company-agents",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Members",
    //             icon: "user",
    //             id: "company-members",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "FAQ",
    //             icon: "faqs",
    //             id: "company-faq",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         },
    //         {
    //             type: "item",
    //             label: "Settings",
    //             icon: "company-settings",
    //             id: "company-settings",
    //             navLinkProps: {
    //                 to: "/"
    //             }
    //         }
    //     ]
    // }
]

const Sidebar = ({ className }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const [currentMenu, setCurrentMenu] = useState(MenuTest);

    const userBookmarks = useSelector(state => state.user)?.profile?.settings?.bookmarks
    const bookmarks = useMemo(() => userBookmarks || [], [userBookmarks])
    const companies = useSelector(state => state.companies.companies)

    useEffect(() => {
        if (bookmarks.length > 0 && companies.length === 0 && !location.pathname.includes("companies")) dispatch(companiesActions.getCompanies({
            fields: ["id", "name", "settings"],
            ids: bookmarks
        }))
    }, [bookmarks, companies, dispatch, location])

    useEffect(() => {
        if (bookmarks.length > 0 && companies.length > 0) {
            setCurrentMenu(currentMenu => {
                const index = currentMenu.findIndex(el => el.label === "Bookmarks")
                const menu = [...currentMenu]
                menu.splice(index, 1, {
                    type: "group",
                    label: "Bookmarks",
                    icon: "star",
                    id: "sidebar-bookmarks",
                    // badge: bookmarks.length,
                    // badgeProps: {
                    //     color: "danger",
                    //     tooltip: <>{"test Header badge"}</>,
                    // },
                    childrens: bookmarks.map(el => {
                        const company = companies.find(company => company.id === el)
                        return company ? ({
                            type: "item",
                            label: company.name,
                            icon: <div style={{ width: "32px" }}><CompanyLogo src={company.settings.logo_url} style={{ width: "20px" }} /></div>,
                            id: "company-bookmark" + el,
                            // badge: bookmarks.length,
                            badgeProps: {
                                color: "primary",
                                // tooltip: "test tooltip badge"
                            },
                            navLinkProps: {
                                to: "/"
                            },
                            customAction: <Button
                                color="flat-primary"
                                className='ms-25'
                                style={{ padding: "2px 0.25rem", height:"60px"}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}>
                                <BookmarkHandler companyId={company.id} icon="close" />
                            </Button>,
                            onClick: () => {
                                console.log("onClick");
                            },
                        }) : null
                    })
                })
                return menu
            })
        }
    }, [bookmarks, companies])

    return <div className="sidebar">
        <RcMenu
            className={className}
            defaultNavLinkComponent={NavLinkDefaultComponent}
            menuIconPrefix={"fad"}
            useIconFromComponentsLibrary={true}
            // edition={true}
            // onUpdate={(newMenu) => {
            //     setCurrentMenu(newMenu);
            // }}
            config={currentMenu}
        />
    </div>
}

export default Sidebar
