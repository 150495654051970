export const BASE_URL = process.env?.REACT_APP_BASE_URL || "https://app.reecall.com"
export const CORE_URL = process.env?.REACT_APP_CORE_URL || "https://connectors.reecall.co"
export const RPA_URL = process.env?.REACT_APP_RC_RPA_BASE || "https://rpa.reecall.co"
export const ACD_URL = process.env?.REACT_APP_ACD_URL || "https://acd.reecall.io"
export const COLLECTION_URL = process.env?.REACT_APP_RC_COLLECTION || "https://collections.reecall.io"
export const CLASSIFIER_URL = process.env?.REACT_APP_CLASSIFIER_URL || "https://classifier.reecall.io"
export const NLU_URL = process.env?.REACT_APP_RC_MSNLU || "https://msnlu.reecall.io"
export const FAQ_URL = process.env?.REACT_APP_RC_FAQ || "https://faq.reecall.io"
export const PHONE_MANAGMENT_URL = process.env?.REACT_APP_PHONE_MANAGMENT_URL || "https://phone.reecall.io"
export const HOOK_URL = process.env?.REACT_APP_RC_HOOKS || "https://hooks.reecall.io"
export const API_BASE =
    // "https://api.staging.reecall.io" ||
    process.env?.REACT_APP_RC_API_BASE || "https://api.reecall.cloud"
export const SEARCH_URL = process.env?.REACT_APP_SEARCH_URL || "https://search.reecall.io"
export const INGESTION_URL = process.env?.REACT_APP_RC_INGESTION || "https://ingestion.reecall.io"
export const BILLING_URL = process.env?.REACT_APP_RC_BILLING || "https://billing.reecall.io"
export const REACT_APP_RC_TWILIO_URL = process.env?.REACT_APP_RC_TWILIO_URL || "https://production.aws.reecall.io/twilio" //"http://localhost:8088" 