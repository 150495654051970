import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import rcSDK from '../services/Api/ApiSdk';

// types

export type ProfileType = { [key: string]: any } | null 
export type BookmarksPendingType = boolean

type InitialStateType = {
    profile: ProfileType,
    bookmarksPending: BookmarksPendingType,
    loading: boolean,
    error: string | null
}

type BookmarksProps = {
    actionType: string,
    companyId: string
}

type Obj = { [key: string]: any }


// definition

const name = 'user';
const initialState = {
    bookmarksPending: false,
    profile: null,
    loading: false,
    error: null
}


// extra actions

const createExtraActions = () => {

    const updateUserBookmarks = createAsyncThunk(
        `${name}/updateUserBookmarks`,
        async ({ actionType, companyId }: BookmarksProps, { getState }) => {
            
            const state = getState() as Obj
            const user = { ...state.user.profile }
            const userId = user.id
            let bookmarks = user.settings.bookmarks || []

            if (actionType === "add") bookmarks = [...bookmarks.filter((id: string) => id !== companyId), companyId]
            if (actionType === "delete") bookmarks = [...bookmarks.filter((id: string) => id !== companyId)]

            const data = { settings: { ...user.settings, bookmarks } }
            const updatedUser = await rcSDK.updateMember(userId, data)
            return updatedUser
        }
    );

    const getMySelf = () => {
        return createAsyncThunk(
            `${name}/getMySelf`,
            async () => await rcSDK
                .getMyself()
                .then((user: { [key: string]: unknown }) => {
                    return user;
                })
        );
    }

    return {
        getMySelf: getMySelf(),
        updateUserBookmarks: updateUserBookmarks
    };
}

// slice

const extraActions = createExtraActions();
const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder: { [key: string]: any }) => {
        return builder

            .addCase(`${name}/getMySelf/pending`, (state: InitialStateType) => {
                state = { ...state, loading: true }
                return state
            })
            .addCase(`${name}/getMySelf/fulfilled`, (state: InitialStateType, action: { payload: PayloadAction }) => {
                state = { ...state, loading: false, profile: action.payload }
                return state
            })
            .addCase(`${name}/getMySelf/rejected`, (state: InitialStateType, action: { error: string }) => {
                state = { ...state, loading: false, error: action.error }
                return state
            })

            .addCase(createExtraActions().updateUserBookmarks.pending, (state: InitialStateType) => {
                state = { ...state, bookmarksPending: true }
                return state
            })
            .addCase(createExtraActions().updateUserBookmarks.fulfilled, (state: InitialStateType, action: { payload: PayloadAction }) => {
                state = { ...state, bookmarksPending: false, profile: action.payload }
                return state
            })
            .addCase(createExtraActions().updateUserBookmarks.rejected, (state: InitialStateType, action: { error: string }) => {
                state = { ...state, bookmarksPending: false, error: action.error }
                return state
            })
    }
});

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;
