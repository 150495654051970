import { useState, useMemo, useCallback, useEffect } from 'react';
import { Table, Icon, CompanyLogo } from '@reecall/components_library/build';
import { twilioActions } from '../../../store/TwilioSlice';
import { useAppDispatch, useAppSelector } from '../../../store/storehooks';
import { FormattedMessage } from 'react-intl';
// import { channelsActions } from '../../../store/ChannelsSlice';
import { usePrevious } from '../../../utility/usePrevious';

type PropsType = {
    row: {
        original: {
            channel: { id: string; name: string; type: string };
            company: { id: string; name: string; logo: string };
            phoneNumber: string;
            id: string;
            available: boolean;
        };
    };
};

const CustomComponentWithIconWrapper = ({
    icon,
    name,
    className,
}: {
    icon: any;
    className?: string;
    name: string | null | undefined;
}) => (
    <div className={`d-flex align-items-center p-50 ms-50 ${className}`}>
        {icon && <div className={`text-primary ${className}`}>{icon}</div>}
        {name && <div className="ms-1">{name}</div>}
    </div>
);

const TwilioTable = () => {
    const twilioReducer = useAppSelector((state) => state.twilio);
    // const channels = useAppSelector((state) => state.channels.channels);
    const { loading, error, numbers, total } = twilioReducer;
    const dispatch = useAppDispatch();

    // useEffect(() => {
    //     const companyIds = numbers.map((number) => number?.company?.id).filter((el) => el);
    //     if (companyIds.length)
    //         dispatch(channelsActions.getChannels({ fields: ['id', 'name', 'type', 'companyId'], companyIds }));
    // }, [numbers, dispatch]);

    const [filters, setFilters] = useState([
        // {
        //     source: 'available',
        //     filter: 'EQUALS',
        //     value: 'true',
        //     type: 'select',
        // },
    ]);

    const [initialFetch, setInitialFetch] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const previousPageIndex = usePrevious(pageIndex);

    useEffect(() => {
        return () => {
            if (!initialFetch) dispatch(twilioActions.clearGetTotalCache())
        }
    }, [dispatch, initialFetch]);

    useEffect(() => {
        setPageCount(Math.ceil(total / pageSize));
    }, [total, pageSize]);

    const initialColumns = useMemo(
        () => [
            {
                label: 'Id',
                accessor: 'id',
                type: 'text',
                editable: false,
                isVisible: false,
                canSort: false,
            },
            {
                label: <FormattedMessage id="phoneNumber" defaultMessage="Phone number" />,
                accessor: 'phoneNumber',
                icon: 'phone',
                type: 'text',
                editable: false,
                canSort: false,
                minWidth: 160,
                sticky: 'left',
                customComponent: (props: PropsType) => {
                    return (
                        <a
                            href={`https://console.twilio.com/us1/develop/phone-numbers/manage/incoming?frameUrl=%2Fconsole%2Fphone-numbers%2Fincoming%2F${props.row.original.id}%3Fx-target-region%3Dus1`}
                            target="_blank"
                            rel="noreferrer"
                            className="ms-50"
                        >
                            {props.row.original.phoneNumber}
                        </a>
                    );
                },
            },
            {
                label: <FormattedMessage id="name" defaultMessage="Name" />,
                icon: 'input-text',
                accessor: 'friendlyName',
                type: 'text',
                minWidth: 250,
                canSort: false,
            },
            {
                label: <FormattedMessage id="availability" defaultMessage="Availability" />,
                icon: 'toggle-off',
                accessor: 'available',
                type: 'select',
                canSort: false,
                options: [
                    {
                        value: 'false',
                        label: 'unavailable',
                    },
                    {
                        value: 'true',
                        label: 'available',
                    },
                ],
                // width: 60,
                customComponent: (props: PropsType) => {
                    return (
                        <CustomComponentWithIconWrapper
                            icon={<Icon iconName={props.row.original?.available ? 'yes' : 'no'} />}
                            name={props.row.original?.available ? 'available' : 'unavailable'}
                            className={props.row.original?.available ? 'text-success' : 'text-danger'}
                        />
                    );
                },
            },
            {
                label: <FormattedMessage id="coreInstance" defaultMessage="Core instance" />,
                icon: 'code-branch',
                accessor: 'coreInstance',
                type: 'text',
                canSort: false,
                // sticky: 'left',
            },
            {
                label: <FormattedMessage id="channel" defaultMessage="Channel" />,
                accessor: 'channel.name',
                icon: 'bullseye',
                type: 'text',
                // type: 'model',
                // model: 'channel',
                editable: false,
                canSort: false,
                minWidth: 250,
                customComponent: (props: PropsType) => {
                    // console.log('ici', row)
                    // const companyId = props.row.original?.company?.id;
                    // channels?.forEach((channel: { [key: string]: string }) => {
                    //     if (channel.companyId === companyId)
                    //         return {
                    //             value: channel.id,
                    //             label: (
                    //                 <div className="text-primary d-flex align-items-center p-50">
                    //                     <Icon iconName={channel.type || 'minus'} />
                    //                     {channel.name && <div className="ms-50">{channel.name}</div>}
                    //                 </div>
                    //             ),
                    //         };
                    // })
                    const channel = props.row.original?.channel;
                    return (
                        <CustomComponentWithIconWrapper
                            icon={<Icon iconName={channel?.type || 'minus'} />}
                            name={channel?.name}
                        />
                    );
                },
            },
            {
                label: <FormattedMessage id="company" defaultMessage="Company" />,
                accessor: 'company.name',
                icon: 'building',
                type: 'text',
                editable: false,
                canSort: false,
                minWidth: 250,
                customComponent: (props: PropsType) => {
                    const company = props.row.original?.company;
                    return (
                        <CustomComponentWithIconWrapper
                            icon={company ? <CompanyLogo src={company?.logo} /> : <Icon iconName={'minus'} />}
                            name={company?.name}
                        />
                    );
                },
            },
        ],
        [
            // channels
        ],
    );

    const [
        columns,
        //  setColumns
    ] = useState(initialColumns);

    const fetchDatas = useCallback(
        ({ controlledPageIndex, pageSize: size }: { controlledPageIndex: number; pageSize: number }) => {
            if (initialFetch || previousPageIndex !== controlledPageIndex || size !== pageSize) {
                setInitialFetch(false);
                setPageSize(size);
                setPageIndex(controlledPageIndex);
                dispatch(
                    twilioActions.getNumbers({
                        pageNumber: controlledPageIndex,
                        pageSize: size,
                        filters,
                        getTotal: initialFetch, //get total only on first fetch for perf reasons
                    }),
                );
            }
        },
        [dispatch, filters, previousPageIndex, pageSize, initialFetch],
    );

    const onFiltersChange = (filters: any) => {
        console.groupCollapsed('onFiltersChange');
        console.log(filters, 'filters');
        console.groupEnd();

        setFilters(filters);
    };

    return error ? (
        <p>error fetching phone numbers</p>
    ) : (
        <div className="d-flex flex-column overflow-hidden" style={{ flex: '1 1 auto' }}>
            <Table
                fetchDatas={fetchDatas}
                data={numbers.map((number) => ({ ...number, available: !number.voiceUrl && !number.smsUrl }))}
                columns={columns}
                pageCount={pageCount}
                onPageChange={(index: number) => {
                    setPageIndex(index);
                }}
                pageIndex={pageIndex}
                totalRecords={total}
                isLoading={loading}
                maxHeight={'calc(100vh - 21rem)'}
                //   updateData={updateData}
                //   updateColumns={updateColumns}
                //   onAddColumn={onAddColumn}
                //   onDeleteColumn={onDeleteColumn}
                filters={filters}
                onFiltersChange={onFiltersChange}
                //   sortBy={sorts}
                //   onSortsChange={onSortsChange}
                showInHeaderBar={{
                    filters: false,
                    sort: false,
                    search: false,
                    edit: false,
                }}
            />
        </div>
    );
};

export default TwilioTable;
