import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rcSDK from '../services/Api/ApiSdk';

// create slice

const name = 'company';
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, extraReducers: (builder) => createExtraReducers(builder) });

// exports

export const companyActions = { ...slice.actions, ...extraActions };
export const companyReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        company: null,
        loading: false,
        error: null
    }
}

function createExtraActions() {
    function getCompany() {
        return createAsyncThunk(
            `${name}/getCompany`,
            async (id) => await rcSDK.getCompany(id).then(company => {
                return company;
            })
        );
    }

    return {
        getCompany: getCompany()
    };    

}

function createExtraReducers(builder) {
    return builder
        .addCase(`${name}/getCompany/pending`, (state, action) => {
            state = {loading: true, company: null, error: null}
            return state
        })
        .addCase(`${name}/getCompany/fulfilled`, (state, action) => {
            state = {loading: false, company: action.payload, error: null}
            return state
        })
        .addCase(`${name}/getCompany/rejected`, (state, action) => {
            state = {loading: false, company: null, error: action.error}
            return state
        })
}
