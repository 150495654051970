import React, { useEffect, useState } from 'react';
import { ListItemRow, Icon, CompanyLogo } from '@reecall/components_library/build';
import { Button, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { companiesActions } from '../../../store/CompaniesSlice';
import { userActions } from '../../../store/UserSlice';

export const BookmarkHandler = ({ companyId, icon = undefined, className = '' }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);

    const [bookmarks, setBookmarks] = useState([]);
    const [bookmarkClicked, setBookmarkClicked] = useState(false);

    useEffect(() => {
        user.profile && setBookmarks(user.profile.settings.bookmarks || []);
        !user.bookmarksPending && setBookmarkClicked(false);
    }, [user]);

    return (
        <div
            className={className}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setBookmarkClicked(true);
                dispatch(
                    userActions.updateUserBookmarks({
                        actionType: bookmarks.includes(companyId) ? 'delete' : 'add',
                        companyId,
                    }),
                );
            }}
        >
            {user.bookmarksPending && bookmarkClicked ? (
                <Spinner size="sm" />
            ) : (
                <Icon iconName={icon || 'star'} iconPrefix={bookmarks.includes(companyId) ? 'fas' : 'far'} />
            )}
        </div>
    );
};

const CompaniesList = () => {
    const dispatch = useDispatch();
    const companiesReducer = useSelector((state) => state.companies);
    const { loading, companies } = companiesReducer;

    useEffect(() => {
        // dispatch(companyActions.getCompany("63bfc71bd2a329000778369a"))
        dispatch(
            companiesActions.getCompanies({
                fields: ['id', 'name', 'address', 'settings'],
            }),
        );
    }, [dispatch]);

    return loading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner
                style={{
                    height: '3rem',
                    width: '3rem',
                }}
                color="primary"
            />
        </div>
    ) : (
        <div className="py-1 overflow-scroll">
            {companies?.map((company) => (
                <ListItemRow.Wrapper
                    className="mb-1 with-icon"
                    key={'companiesListRow' + company.id}
                    onClick={() => console.log('company click')}
                >
                    <div style={{ width: '50px' }}>
                        <CompanyLogo src={company.settings.logo_url} />
                    </div>
                    <ListItemRow.InnerWrapper>
                        <ListItemRow.Title>{company.name}</ListItemRow.Title>
                        <span className="text-truncate">{company.address}</span>
                    </ListItemRow.InnerWrapper>
                    <ListItemRow.Actions>
                        <BookmarkHandler companyId={company.id} className="me-50" />
                        <Button className="btn-icon btn-round" color="light" size="sm">
                            <Icon iconName={'ellipsis'} />
                        </Button>
                    </ListItemRow.Actions>
                </ListItemRow.Wrapper>
            ))}
        </div>
    );
};

export default CompaniesList;
