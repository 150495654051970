import { createSlice } from '@reduxjs/toolkit';

const name = 'app';
const initialState = createInitialState();
const reducers = createReducer();
const slice = createSlice({ name, initialState, reducers });

// exports

export const appActions = { ...slice.actions };
export const appReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        theme: "light"
        // theme: "dark"
    }
}

function createReducer() {
    return {
        changeTheme,
    };

    function changeTheme(state, action) {
        state.theme = action.payload;
    }
}