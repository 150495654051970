import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import ProtectedRoute from './ProtectedRoute';
import UnauthentifiedRoute from './UnauthentifiedRoute';

import FullLayout from '../layout/layouts/FullLayout';
import MainLayout from '../layout/layouts/MainLayout';

// import PageTest from '../app/pages/PageTest';
import { useNavigate, useLocation } from 'react-router-dom';


import LoginPage from '../app/pages/Auth/LoginPage';

import NoMatchPage from '../app/pages/Misc/NoMatchPage';
import NoAuthorizedPage from '../app/pages/Misc/NotAuthorizedPage';
import List from '../app/pages/companies/List';
import TwilioPage from '../app/pages/settings/TwilioPage';

const AppRouter = () => {
    // const auth = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/") {
        navigate('/companies')
      }
    }, [location, navigate]);


    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route index element={<></>} />
            </Route>

            <Route element={<UnauthentifiedRoute />}>
                <Route element={<FullLayout className="bg-primary" />}>
                    <Route path="login" element={<LoginPage />} />
                </Route>
            </Route>

            <Route path="not-authorized" element={<NoAuthorizedPage />} />
            <Route path="*" element={<NoMatchPage />} />
            <Route path="/companies" element={<MainLayout children={<List />} />} />
            <Route path="/twilio" element={<MainLayout children={<TwilioPage />} />} />

            {/* <Route element={<MainLayout />}>
                <Route index element={<PageTest />} />
            </Route>

            <Route element={<UnauthentifiedRoute />}>
                <Route element={<FullLayout className="bg-primary"/>}>
                    <Route path="login" element={<LoginPage />} />
                </Route>
            </Route>

            <Route element={<ProtectedRoute isAuthenticated={auth.isAuthenticated} />}>
                <Route element={<MainLayout />}>
                    <Route path="main1" element={<PageTest>{"Main 1"}</PageTest>} />
                    <Route path="main2" element={<PageTest>{"Main 2"}</PageTest>} />
                </Route>

                <Route element={<FullLayout />}>
                    <Route path="full" element={<PageTest>{"Full"}</PageTest>} />
                </Route>
            </Route>

            <Route path="not-authorized" element={<NoAuthorizedPage />} />
            <Route path="*" element={<NoMatchPage />} /> */}
        </Routes>
    );
};

export default AppRouter;
