import { createSlice } from '@reduxjs/toolkit'

export interface BookmarksState {
    bookmarks: string[]
}

const initialState = { bookmarks: [] } as BookmarksState

const bookmarksSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addBookmark: (state, action) => {
            state.bookmarks.push(action.payload)
        },
        deleteBookmark: (state, action) => {
            state.bookmarks = [...state.bookmarks.filter(el => el !== action.payload)]
        },
    }
})

export const { addBookmark, deleteBookmark } = bookmarksSlice.actions
export const bookmarksReducer = bookmarksSlice.reducer
