import './assets/scss/app.scss';
import '@reecall/components_library/build/bundle.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

import { ThemeProvider } from 'styled-components';

import AppRouter from './router/Router';
import { BrowserRouter } from 'react-router-dom';
import { userActions } from './store/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { DarkTheme, LightTheme } from '@reecall/components_library/build';

library.add(fas, fal, fab, far, fad);

const App = () => {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);
    const theme = useSelector((state) => state.app.theme);

    // useEffect(() => {
    //     document.body.classList.add(`${theme}-layout`);
    // }, [theme])

    useEffect(() => {
        theme === 'dark'
            ? document.documentElement.setAttribute('darkMode', '')
            : document.documentElement.removeAttribute('darkMode', '');
    }, [theme]);

    useEffect(() => {
        if (auth.isAuthenticated && !user.profile && !user.loading && !user.error) {
            dispatch(userActions.getMySelf());
        }
    }, [auth, user, dispatch]);

    return (
        <>
            <ThemeProvider theme={theme === 'dark' ? DarkTheme : LightTheme}>
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
};

export default App;
