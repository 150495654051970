import { Col } from 'reactstrap';
import { PageLayout } from '@reecall/components_library/build';
import TwilioTable from './TwilioTable';

const TwilioPage = () => {
    return (
        <>
            <PageLayout.PageHeader className={'py-0'}>
                <Col sm="12" className="col-left">
                    {/* <PageLayout.PagePrevBtn /> */}
                    <PageLayout.PageTitle>{'Twilio'}</PageLayout.PageTitle>
                </Col>
            </PageLayout.PageHeader>
            <div className="h-100 my-1" style={{ border: '1px solid #E5EAEF', borderRadius: "6px" }}>
                <TwilioTable />
            </div>
        </>
    );
};

export default TwilioPage;
