import React from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from '../navbar/NavBar';
import Sidebar from '../sidebar/Sidebar';
import styled, { useTheme } from 'styled-components';

const StyledSidebarWrapper = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.menuBg};
    box-shadow: 0 0 15px #22292f1a;
`;

const MainLayout = ({ children }) => {
    const theme = useTheme();
    return (
        <>
            <NavBar />
            <div className="content-wrapper">
                <StyledSidebarWrapper theme={theme}>
                    <Sidebar />
                </StyledSidebarWrapper>
                <div className="content">
                    {children || <Outlet />}
                </div>
            </div>
        </>
    );
};

export default MainLayout;
