import { configureStore } from '@reduxjs/toolkit';
import logger, { createLogger } from 'redux-logger'

import { authReducer } from '../services/Auth/AuthSlice';
import { userReducer } from './UserSlice';
import { appReducer } from './AppSlice';
import { companiesReducer } from './CompaniesSlice';
import { companyReducer } from './CompanySlice';
import { bookmarksReducer } from './BookmarkSlice';
import { twilioReducer } from './TwilioSlice';
import { channelsReducer } from './ChannelsSlice';

declare var process: {
  env: {
    NODE_ENV: string
  }
}
const DEBUG = ["development", "staging"].includes(process.env.NODE_ENV) ? true : false;

const loggerMiddleware = createLogger({
  collapsed: (logEntry: { [key: string]: any }) => !logEntry.error
})

const middlewares: any = [DEBUG && loggerMiddleware].filter(Boolean);

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    companies: companiesReducer,
    company: companyReducer,
    bookmarks: bookmarksReducer,
    twilio: twilioReducer,
    channels: channelsReducer,
  },
  // middleware: new MiddlewareArray().concat(middlewares, logger),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares, logger),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch