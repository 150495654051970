import React, { useState } from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Nav
} from "reactstrap"
import { Avatar } from "@reecall/components_library/build"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { authActions } from "../../services/Auth/AuthSlice";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom"
import screenfull from 'screenfull';

const UserDropdown = ({ user, isFullScreen, isAuthenticated, toggleFullScreen }) => {
    const dispatch = useDispatch()
    return (<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link d-flex align-items-center">
            <div className="d-lg-flex d-none flex-column me-1">
                <span className="user-name">
                    {user?.firstName} {user?.lastName}
                </span>
            </div>
            <Avatar
                element={user}
                size="md"
            />
        </DropdownToggle>
        <DropdownMenu end>
            <DropdownItem
                tag="a"
                href=""
                onClick={e => {
                    e.preventDefault()
                    toggleFullScreen()
                }}
            >
                {isFullScreen ? <FontAwesomeIcon icon={["far", "compress"]} className="me-1" /> : <FontAwesomeIcon icon={["far", "expand"]} className="me-1" />}
                <span className="align-middle"><FormattedMessage id="navbar.fullscreen" defaultMessage="Full screen" /></span>
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem
                tag={NavLink}
                to="/settings/my-profil"
            >
                <FontAwesomeIcon icon={["far", "user"]} className="me-1" />
                <span className="align-middle"><FormattedMessage id="navbar.myprofil" defaultMessage="My profile" /></span>
            </DropdownItem>

            <DropdownItem
                tag="a"
                href="/pages/login"
                onClick={e => {
                    e.preventDefault()
                    dispatch(authActions.logout());
                    // if (isAuthenticated) {
                    //     return dispatch(authActions.logout());
                    // }
                }}
            >
                <FontAwesomeIcon icon={["far", "power-off"]} className="me-1" />
                <span className="align-middle"><FormattedMessage id="navbar.logout" defaultMessage="Log Out" /></span>
            </DropdownItem>
        </DropdownMenu>
    </UncontrolledDropdown>
    )
}

const NavRight = () => {

    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.user);
    const state = useSelector(state => state);
    console.log(state)

    const [fullScreen, setFullScreen] = useState(false);

    const toggleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
            setFullScreen(!fullScreen);
        }
    }

    return <Nav navbar className="ml-auto align-items-center flex-row">
        {
            !user.loading  && <UserDropdown
                user={user?.profile}
                isAuthenticated={auth.isAuthenticated}
                toggleFullScreen={() => toggleFullScreen()}
                isFullScreen={fullScreen}
            />
        }
    </Nav>
}

export default NavRight;
