import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rcSDK from '../services/Api/ApiSdk';

// types

type CompaniesType = {
  companies: { id: number, name: string, settings: { logo_url: string, [key: string]: any }, [key: string]: any }[]
}

type GetCompaniesParameters = { fields: string[], ids: string[] }

type InitialStateType = {
  companies: CompaniesType,
  loading: boolean,
  error: string | null
}

// state

const name: string = 'companies';
const initialState = {
  companies: [],
  loading: false,
  error: null
}

// implementation

const createExtraActions = () => {
  const getCompanies = () => {
    return createAsyncThunk(
      `${name}/getCompanies`,
      async (parameters: GetCompaniesParameters) => {
        let where = {}
        if (parameters.ids) where = { id: { inq: parameters.ids } }
        return await rcSDK.getCompanies()
          .where(where)
          .fields(parameters.fields)
          .then((companies: CompaniesType) => {
            return companies;
          })
      }
    );
  }

  return {
    getCompanies: getCompanies()
  };

}

const createExtraReducers = (builder: any) => {
  return builder
    .addCase(`${name}/getCompanies/pending`, (state: InitialStateType) => {
      state = { ...state, loading: true }
      return state
    })
    .addCase(`${name}/getCompanies/fulfilled`, (state: InitialStateType, action: { payload: CompaniesType }) => {
      state = { ...state, loading: false, companies: action.payload }
      return state
    })
    .addCase(`${name}/getCompanies/rejected`, (state: InitialStateType, action: { error: string }) => {
      state = { ...state, loading: false, error: action.error }
      return state
    })
}

const extraActions = createExtraActions();
const slice = createSlice({
  name,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => createExtraReducers(builder)
});

// exports

export const companiesActions = { ...slice.actions, ...extraActions };
export const companiesReducer = slice.reducer;

