import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rcSDK from '../services/Api/ApiSdk';

// types

type ChannelsType = { id: number, name: string, type: string, [key: string]: any }[]

type GetChannelsParameters = { fields?: string[], companyIds?: string[], ids?: string[] }

type InitialStateType = {
  channels: ChannelsType,
  loading: boolean,
  error: string | null
}

// state

const name: string = 'channels';
const initialState = {
  channels: [],
  loading: false,
  error: null
}

// implementation

const createExtraActions = () => {
  const getChannels = () => {
    return createAsyncThunk(
      `${name}/getChannels`,
      async (parameters: GetChannelsParameters) => {
        let where = {}
        if (parameters.companyIds) where = { companyId: { inq: parameters.companyIds } }
        if (parameters.ids) where = { id: { inq: parameters.ids } }
        return await rcSDK.getChannels()
          .where(where)
          .fields(parameters.fields)
          .then((channels: ChannelsType) => {
            return channels;
          })
      }
    );
  }

  return {
    getChannels: getChannels()
  };

}

const createExtraReducers = (builder: any) => {
  return builder
    .addCase(`${name}/getChannels/pending`, (state: InitialStateType) => {
      state = { ...state, loading: true }
      return state
    })
    .addCase(`${name}/getChannels/fulfilled`, (state: InitialStateType, action: { payload: ChannelsType }) => {
      state = { ...state, loading: false, channels: action.payload }
      return state
    })
    .addCase(`${name}/getChannels/rejected`, (state: InitialStateType, action: { error: string }) => {
      state = { ...state, loading: false, error: action.error }
      return state
    })
}

const extraActions = createExtraActions();
const slice = createSlice({
  name,
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => createExtraReducers(builder)
});

// exports

export const channelsActions = { ...slice.actions, ...extraActions };
export const channelsReducer = slice.reducer;

